
document.addEventListener('DOMContentLoaded', function () {
    // MOBILE MENU
    var mobileMenuOpen = false
    document.querySelectorAll('#menu-menu-1 li a').forEach(function (item) {
        item.addEventListener('click', function () {
            if (mobileMenuOpen) {
                document.querySelector('#mobile-menu-2').style.display = "none"
                mobileMenuOpen = false
            }
        })
    })
    //
    document.querySelector('#mobileMenuToggle').addEventListener('click', function () {
        if (mobileMenuOpen == true) {
            document.querySelector('#mobile-menu-2').style.display = "none"
            mobileMenuOpen = false
        } else {
            document.querySelector('#mobile-menu-2').style.display = "block"
            mobileMenuOpen = true
        }
    })

    // ACCORDION TOGGLER
    function toggleAccordion(x) {
        var headingElement = document.getElementById(`accordion-collapse-heading-${x}`);
        var bodyElement = document.getElementById(`accordion-collapse-body-${x}`);

        if (bodyElement.classList.contains("hidden")) {
            bodyElement.classList.remove("hidden");
        } else {
            bodyElement.classList.add("hidden");
        }
    }

    //STICKY HEADER TOGGLER
    var header = document.querySelector("header");
    var sticky = header.offsetTop;
    function scrollPage() {
        if (window.scrollY > sticky) {
            header.classList.add('sticky')
            document.querySelector('header .topBar').style.display='flex'
		} else {
            header.classList.remove('sticky')
            document.querySelector('header .topBar').style.display = 'none'
		}
    }
    scrollPage()
    window.onscroll = function () {
		scrollPage()
	}

    //PORTFOLIO, TRANSITION PROFECTS IMAGES UPWARDS ON MOUSEOVER
    const portfolioImages = document.querySelectorAll('.pro img')

	portfolioImages.forEach((image) => {
		image.addEventListener('mouseover', () => {
			image.style.transition = 'transform 5s'
			image.style.transform =
				'translateY(-' + image.clientHeight / 1.35 + 'px)'
		})

		image.addEventListener('mouseout', () => {
			image.style.transition = 'transform 0.5s'
			image.style.transform = 'translateY(0)'
		})
	})


    // ADD CLICK EVENT
    for (let x = 1; x <= 10; x++) {
        var headingElement = document.getElementById(`accordion-collapse-heading-${x}`);
        headingElement.addEventListener("click", () => toggleAccordion(x));
    }

    // ANIMATIONS
    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        ); 
    }

    function handleScroll() {
        const elements = document.querySelectorAll('.fade-in, .fade-in-from-right');
        elements.forEach((element) => {
            if (isElementInViewport(element)) {
                element.classList.add('fade-in-active');
            }
        });
    }

    // Trigger the fade-in animations when the page loads and on scroll
    document.addEventListener('DOMContentLoaded', handleScroll);
    window.addEventListener('scroll', handleScroll);


    //REVIEWS SLIDER
    document.addEventListener('DOMContentLoaded', function () {
        var mySiema = new Siema({
            selector: '.siema'
        });
    });

})